<template>
  <div class="region">
    <v-alert
      v-for="alert in alerts"
      :key="alert.content"
      :type="alert.type"
    >
      {{ alert.message }}
    </v-alert>

    <v-card>
      <v-img
        :src="(region.image_display_url) ? region.image_display_url : ''"
        height="200px"
        class="px-3"
      >
        <v-row class="mb-4">
          <v-col>
            <div class="headline">
              {{ region.name }}
            </div>
            <div
              v-if="details"
              class="title"
            >
              {{ details.num_quests }} Quests
            </div>
          </v-col>
        </v-row>
        <v-row
          v-if="details"
          class="align-end pt-5"
        >
          <v-col>
            <div class="title">
              {{ details.num_profiles }}
            </div>
            <div>
              Participants
            </div>
          </v-col>
          <v-col align="right">
            <div class="title">
              {{ region.points_earned }}
            </div>
            <div>
              Points Earned
            </div>
          </v-col>
        </v-row>
      </v-img>
    </v-card>

    <v-row>
      <v-col>
        <Share
          :title="region.name"
          class="mr-4"
        />
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab>
        Leaderboard
      </v-tab>
      <v-tab>
        Quests
      </v-tab>
      <v-tab v-if="canViewEvents">
        Events
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
    >
      <v-tab-item>
        <Loading
          v-if="detailsLoading"
          :loading="detailsLoading"
          message="Loading Leaderboard"
        />
        <LeaderList
          v-if="details"
          :entry-list="details.profiles"
          :region="region"
        />
        <v-card v-if="detailsError">
          <v-card-text>
            Failed to download leaderboard.
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <quest-list :region-id="region.id" />
      </v-tab-item>
      <v-tab-item v-if="canViewEvents">
        <EventList
          :can-create="false"
          :url="`/quest/event/?region=${region.id}`"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import EventList from '@/components/EventList.vue'
import LeaderList from '@/components/LeaderList.vue'
import Loading from '@/components/Loading.vue'
import QuestList from '@/components/QuestList.vue'
import Share from '@/components/Share.vue'
import { mapState } from 'vuex'

export default {
  components: {
    EventList,
    LeaderList,
    Loading,
    QuestList,
    Share,
  },
  data: function() {
    return {
      details: null,
      alerts: [],
      tab: null,
      detailsError: false,
      detailsloading: false,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profile,
    }),
    canViewEvents() {
      return this.profile.allow_events
    },
  },
  created: function () {
    this.region = this.$store.getters['regions/getBySlug'](this.$route.params.slug) || false
    if (!this.region) {
      return this.$router.push('/not-found/')
    }
    this.getDetails()
  },
  methods: {
    getDetails() {
      this.detailsLoading = true
      return this.$store.dispatch('regions/getDetails', '/quest/region/' + this.region.id + '/')
        .finally(() => {
          this.details = this.$store.getters['regions/getDetailsByID'](this.region.id) || null
          this.detailsLoading = false
          if(!this.details) {
            this.detailsError = true
            if (!this.$online) {
              this.alerts.push({
                type: 'error',
                message: 'An internet connection is required to download the details of this region.',
              })
            } else {
              this.alerts.push({
                type: 'error',
                message: 'Failed to download region details.',
              })
            }
          }
        })
    },
  },
}
</script>
